<template>
  <div
    class="flex flex--center flex--vcenter"
    :class="{ 'flex--column sticky': vertical }"
  >
    <break v-if="!vertical" :size="spacing" direction="right" />
    <p class="title text--center text--smaller">
      Partagez cet article
    </p>
    <break :size="spacing" :direction="vertical ? 'bottom' : 'right'" />
    <div
      v-for="key in websites"
      :key="key.name"
      :class="vertical ? '' : 'flex'"
    >
      <share-button
        :url="getShareUrl(key.name)"
        :color="color"
        :size="size"
        :name="key.name"
      />
      <break :size="spacing" :direction="vertical ? 'bottom' : 'right'" />
    </div>
  </div>
</template>
<script>
import ShareButton from './share-button.vue';

export default {
  components: {
    ShareButton,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    vertical: {
      type: Boolean,
      required: true,
    },
    spacing: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
    websites: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getShareUrl(name) {
      switch (name) {
        case 'facebook-f':
          return `https://www.facebook.com/sharer/sharer.php?u${this.url}`;
        case 'twitter':
          return `https://twitter.com/intent/tweet?text=${this.url}&via=Study__Advisor`;
        case 'linkedin-in':
          return `http://www.linkedin.com/shareArticle?mini=true&url=${this.url}`;
        default:
          return null;
      }
    },
  },
};
</script>
