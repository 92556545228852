<template>
  <div
    class="card__container"
    :class="showEmail
      ? 'card__container--thin-calendar card__container--shmedium'
      : 'card__container--medium' "
    :style="'margin-bottom:' + breakSize + 'px;'"
  >
    <div
      class="card__header card__header--bigger cover cover--background-position-top"
      :class="showEmail
        ? 'card__header--bigger'
        : 'card__header--huge'"
      :style="`background-image: url('` + teamMember.picture + `');`"
    >
      <div class="flex flex--between" />
    </div>
    <div class="card__content">
      <p class="title title--small bold text--center">
        {{ teamMember.first_name }}
      </p>
      <div class="card__description">
        <p class="text bolder text--grey text--center">
          {{ teamMember.function }}
        </p>
      </div>
      <break
        v-if="!showEmail"
      />
      <p
        v-if="showEmail"
        class="text text--link text--center"
      >
        <a
          :href="`mailto:${teamMember.email }`"
        >
          {{ teamMember.email }}
        </a>
      </p>
      <p
        v-else
        class="text text--grey text--center"
      >
        « {{ teamMember.description }} »
      </p>
      <break />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teamMember: {
      type: Object,
      required: true,
    },
    breakSize: {
      type: Number,
      required: false,
      default: 20,
    },
    showEmail: {
      type: Boolean,
    },
  },
};
</script>


<style scoped>
  .cover--background-position-top {
    background-position: top;
  }
</style>
