<template>
  <div class="info-pill" :style="`background-color: ${color};`">
    <h4 class="text text--smaller text--white" v-html="text" />
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
      default: '#F65661',
    },
  },
  methods: {},
};
</script>
