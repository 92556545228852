<template>
  <div :style="'margin-bottom:' + breakSize + 'px;'">
    <div class="card card__container grow  card__container--height">
      <div
        class="card__header flex flex--end card__header--search cover"
        v-html="establishment.cover_tag + establishment.image_tag"
        :alt="establishment.name"
      />
      <div class="card__content card__content--compressed">
        <break :size="25" />
        <h3
          v-if="header === 3"
          v-line-clamp:30="2"
          class="sub-title--small bolder text--left card__two-line-text"
        >
          {{ establishment.name }}
        </h3>
        <h2
          v-if="header === 2"
          v-line-clamp:30="2"
          class="sub-title--small bolder text--left card__two-line-text"
        >
          {{ establishment.name }}
        </h2>
        <h4
          v-if="header === 4"
          v-line-clamp:30="2"
          class="sub-title--small bolder text--left card__two-line-text"
        >
          {{ establishment.name }}
        </h4>
        <break :size="10" />
        <p
          v-if="establishment.tagline"
          v-line-clamp:30="2"
          class="text text--left text--grey card__two-line-text"
        >
          {{ establishment.tagline }}
        </p>
        <break
          v-if="establishment.tagline"
          :size="10"
        />
        <div
          v-for="detail in details"
          :key="detail.label"
        >
          <detail
            :label="detail.label"
            :value="detail.value"
            :icon="detail.icon"
          />
          <break :size="3" />
        </div>
      </div>
      <break :size="35" />
      <div class="card__hover card__hover--details">
        <break :size="5" />
        <p class="text blod text--white">
          {{ hover }}
        </p>
      </div>
      <a
        :href="establishment.path"
        class="card__link--full"
        @click="$root.trackAhoy('Click', establishment.id, trackType)"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import ahoy from 'ahoy.js';
import Detail from './components/school_detail.vue';

export default {
  components: {
    Detail,
  },
  props: {
    establishment: {
      type: Object,
      required: true,
    },
    breakSize: {
      type: Number,
      required: false,
      default: 20,
    },
    track: {
      type: Boolean,
      required: false,
      default: false,
    },
    trackType: {
      type: String,
      required: false,
      default: '',
    },
    header: {
      type: Number,
      required: false,
      default: 3,
    },
  },
  data() {
    return {
      details: [],
      hover: '',
    };
  },
  mounted() {
    axios.get(`${this.$props.establishment.path}/get_card_details.json`)
      .then((response) => {
        this.details = response.data.details;
        this.hover = response.data.number;
      });
    if (this.track === true) {
      ahoy.track('Display', {
        target_id: parseInt(this.establishment.id, 10),
        target_type: this.trackType,
      });
    }
  },
};
</script>
<style scoped>
  .text,
  .sub-title--small {
    word-break: normal !important;
    display: block !important;
  }
</style>
