<template>
  <div
    class="flex full flex--wrap flex--vend "
    :class="$mq == 'phone' || $mq == 'tablet' ? 'flex--around' : ''"
  >
    <div>
      <datepicker
        v-model="beginDate"
        :inline="true"
        :language="fr"
        :disabled-dates="disabledDates"
        :format="customFormatter"
        input-class="datepicker-input"
        wrapper-class="datepicker-wrapper"
        @selected="recieve"
        @load="recieve"
      />
    </div>
    <mq-layout mq="small_cpu+">
      <break
        v-if="horizontal"
        :size="50"
        direction="left"
      />
    </mq-layout>
    <mq-layout
      mq="small_cpu+"
      :class="horizontal ? '' : 'full'"
    >
      <break :size="20" />
      <p class="text text__paragraph--left">
        Précise l'heure :
      </p>
      <break :size="10" />
      <v-select
        v-model="selected"
        label="title"
        :options="apiData"
        :multiple="false"
        :reset-on-options-change="true"
        @input="changeDate($event)"
      >
        {{ firstValue }}
        <template #search="{attributes, events}">
          <input
            id="js--hide-calendar"
            class="vs__search"
            :required="!selected"
            v-bind="attributes"
            v-on="events"
          >
        </template>
      </v-select>

      <input
        type="hidden"
        required="true"
        name="appointment[date]"
        :value="selectedDateTime"
      >
      <break :size="20" />
      <input
        id="js--enable"
        type="submit"
        class="btn btn--big btn--green full btn--disabled "
        value="Confirmer"
        disabled="true"
      >
    </mq-layout>
    <mq-layout
      :mq="['phone', 'tablet']"
      class="full"
    >
      <break :size="20" />
      <p class="text text__paragraph--left">
        Précise l'heure :
      </p>
      <break :size="10" />
      <v-select
        v-model="selected"
        label="title"
        :options="apiData"
        :multiple="false"
        :reset-on-options-change="true"
        @input="changeDate($event)"
      >
        <template #search="{attributes, events}">
          <input
            id="js--hide-calendar"
            class="vs__search"
            :required="!selected"
            v-bind="attributes"
            v-on="events"
          >
        </template>
      </v-select>

      <input
        type="hidden"
        required="true"
        name="appointment[date]"
        :value="selectedDateTime"
      >
      <break :size="20" />
      <input
        id="js--enable"
        type="submit"
        class="btn btn--big btn--green full btn--disabled "
        value="Confirmer"
        disabled="true"
      >
    </mq-layout>
  </div>
</template>


<script>
import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import { fr } from 'vuejs-datepicker/dist/locale';


export default {
  components: {
    Datepicker,
  },
  props: {
    counselor: {
      type: Object,
      required: true,
    },
    horizontal: Boolean,
    selected: Boolean,
    tracking: Boolean,
  },
  data() {
    return {
      beginDate: new Date().setDate(new Date().getDate() + 1),
      fr,
      firstValue: '',
      disabledDates: {
        to: new Date(),
        days: this.counselor.days_without_availabilities,
      },
      apiData: this.recieve(),
      pickedDateFormatted: '',
      selectedDateTime: '',
    };
  },
  watch: {
    setBeginDate() {
      this.$parent.dateListener(this.beginDate);
    },
  },

  methods: {
    customFormatter(date) {
      const pickedDate = new Date(date);
      this.pickedDateFormatted = `${pickedDate.getDate()}-${pickedDate.getMonth() + 1}-${pickedDate.getFullYear()}`;
    },
    recieve(date) {
      const pickedDate = new Date();
      pickedDate.setDate(new Date().getDate() + 1);
      if (date !== undefined) {
        this.customFormatter(date);
      }
      axios.get(`/counselors/get_availabilities_by_day.json?counselor_id=${this.counselor.id}&date=${this.pickedDateFormatted !== undefined ? this.pickedDateFormatted : `${pickedDate.getDate()}-${pickedDate.getMonth() + 1}-${pickedDate.getFullYear()}`}${this.tracking === true ? "&match" : ""}`)
        .then((response) => {
          // eslint-disable-next-line no-new-object
          this.apiData = response.data.map((h) => new Object({
            title: `${h}h`,
            code: h,
          }));
        });
    },
    changeDate(value) {
      if (value != null) {
        this.selectedDateTime = `${this.pickedDateFormatted} ${value.code}`;
        document.getElementById('js--enable').classList.remove('btn--disabled');
        document.getElementById('js--enable').disabled = false;
      } else {
        document.getElementById('js--enable').disabled = true;
        document.getElementById('js--enable').classList.add('btn--disabled');
      }
    },

  },
};
</script>
