// eslint-disable-next-line import/no-unresolved
import 'core-js/stable';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
// eslint-disable-next-line import/no-unresolved
import Vue from 'vue';
import ahoy from 'ahoy.js';

// import * as _flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

// CARDS
import VModal from 'vue-js-modal';
import VueMq from 'vue-mq';
import VueSanitizeHtml from 'vue-sanitize-html-plugin';
import InstantSearch from 'vue-instantsearch';
import { VueMasonryPlugin } from 'vue-masonry';
import VueYoutube from 'vue-youtube';
import { Timeline } from 'vue-tweet-embed';
import vSelect from 'vue-select';
import * as VueGoogleMaps from 'vue2-google-maps';
import lineClamp from 'vue-line-clamp';
import VueTelInput from 'vue-tel-input';
import StarRating from 'vue-star-rating';
import { VLazyImagePlugin } from 'v-lazy-image';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import CardCounselor from '../components/cards/card_counselor.vue';
import CardSchool from '../components/cards/card_establishment.vue';
import CardCounselorHorizontal from '../components/cards/card_counselor_horizontal.vue';
import CardCtaProspect from '../components/cards/card_cta_prospect.vue';

import ModalImage from '../components/modal_image.vue';
import ReasonModal from '../components/reason_modal.vue';
import Rating from '../components/home/rating.vue';
import BlogCard from '../components/home/blog_card.vue';
import CommunityNumber from '../components/home/color_number.vue';
import CategoriesHome from '../components/home/categories_home.vue';
import CategoryCard from '../components/home/category_card.vue';
import SiteVideo from '../components/schools/site_video.vue';
import SiteImage from '../components/schools/site_image.vue';
import ProgramsSearch from '../components/programs/programs_search.vue';
import BurgerMenu from '../components/burger_menu.vue';
import TeamMember from '../components/home/team_member.vue';
import AlgoliaSearch from '../components/algolia/algolia_search.vue';
import PartnerLogo from '../components/home/partner_logo.vue';
import Slider from '../components/home/slider.vue';
import Break from '../components/commodities/break.vue';
import Sharer from '../components/commodities/sharer.vue';
import Tag from '../components/commodities/tag_link.vue';
import SelectNavigation from '../components/commodities/select_navigation.vue';
import ColorTag from '../components/commodities/color_tag.vue';
import MapWithMarkers from '../components/maps/map_with_markers.vue';
import JumpTo from '../components/commodities/jump_to_button.vue';
import Education from '../components/counselors/education.vue';
import Flash from '../components/commodities/flash.vue';
import Line from '../components/commodities/line.vue';
import Calendar from '../components/commodities/calendar.vue';
import SelectSchools from '../components/commodities/select_schools.vue';
import LazyImage from '../components/commodities/lazy_image.vue';
import DatesPicker from '../components/commodities/dates_picker.vue';
import TimePicker from '../components/commodities/time_picker.vue';
import SchoolsSearch from '../components/schools/schools_search.vue';
// register globally

//  CSS
import 'vue-select/dist/vue-select.css';
import '../src/index.scss';

Vue.component('counselor', CardCounselor);
Vue.component('school', CardSchool);
Vue.component('counselor-horizontal', CardCounselorHorizontal);
Vue.component('card-cta-prospect', CardCtaProspect);
Vue.component('star-rating', StarRating);

Vue.use(VueTelInput);
Vue.use(VLazyImagePlugin);
Vue.use(VueYoutube);
Vue.use(lineClamp);
Vue.use(VueMasonryPlugin);
Vue.use(InstantSearch);
Vue.use(VueSanitizeHtml, {
  allowedTags: [
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'p',
    'a',
    'ul',
    'ol',
    'nl',
    'li',
    'b',
    'i',
    'strong',
    'em',
    'strike',
    'code',
    'hr',
    'br',
    'div',
    'table',
    'thead',
    'caption',
    'tbody',
    'tr',
    'th',
    'td',
    'pre',
    'iframe',
  ],
  allowedAttributes: {
    a: ['href', 'name', 'target', 'class'],
    // We don't currently allow img itself by default, but this
  },
  // Lots of these won't come up by default because we don't allow them
  selfClosing: [
    'img',
    'br',
    'hr',
    'area',
    'base',
    'basefont',
    'input',
    'link',
    'meta',
  ],
  // URL schemes we permit
  allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
  allowedSchemesByTag: {},
  allowedSchemesAppliedToAttributes: ['href', 'src', 'cite', 'class'],
  allowProtocolRelative: true,
});

Vue.use(VueReCaptcha, {
  siteKey: '6LfZoNgUAAAAAJG9q-fpR1u3HlBnDfypXNgJ127a',
  loaderOptions: {
    useRecaptchaNet: true,
  },
});

Vue.use(VueMq, {
  breakpoints: {
    phone: 601,
    tablet: 800,
    small_cpu: 1200,
    medium_cpu: 1500,
    big_cpu: Infinity,
  },
});

Vue.use(VModal);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBfpsnCqxM0DkQPPMSqCXxGUCCfDj6F2Ko',
    libraries: 'places',
  },
});
Vue.component('time-picker', TimePicker);
Vue.component('dates-picker', DatesPicker);
Vue.component('reason-modal', ReasonModal);
Vue.component('lazy-image', LazyImage);
Vue.component('select-schools', SelectSchools);
Vue.component('flash', Flash);
Vue.component('jump-to', JumpTo);
Vue.component('education', Education);
Vue.component('color-tag', ColorTag);
Vue.component('tag', Tag);
Vue.component('map-with-markers', MapWithMarkers);
Vue.component('school', CardSchool);
Vue.component('v-select', vSelect);
Vue.component('rating', Rating);
Vue.component('categories-home', CategoriesHome);
Vue.component('category-card', CategoryCard);
Vue.component('blog-card', BlogCard);
Vue.component('site-video', SiteVideo);
Vue.component('site-image', SiteImage);
Vue.component('programs-search', ProgramsSearch);
Vue.component('schools-search', SchoolsSearch);
Vue.component('algolia-search', AlgoliaSearch);
Vue.component('burger-menu', BurgerMenu);
Vue.component('partner-logo', PartnerLogo);
Vue.component('team-member', TeamMember);
Vue.component('slider', Slider);
Vue.component('break', Break);
Vue.component('sharer', Sharer);
Vue.component('v-select', vSelect);
Vue.component('select-navigation', SelectNavigation);
Vue.component('calendar', Calendar);
Vue.component('modal-image', ModalImage);
Vue.component('grey-line', Line);

document.addEventListener('DOMContentLoaded', () => {
  // eslint-disable-next-line no-unused-vars
  const app = new Vue({
    el: "[data-behaviour='vue']",
    components: {
      CommunityNumber,
      Timeline,
    },
    props: {
      originatorId: {
        type: Number,
        required: false,
        default: null,
      },
      originatorType: {
        type: String,
        required: false,
        default: null,
      },
    },
    data: {
      rating: 0,
      boundRating: 0,
    },
    mounted() {
      this.originatorId = this.$el.dataset
        ? this.$el.dataset.originatorId
        : undefined;
      this.originatorType = this.$el.dataset
        ? this.$el.dataset.originatorType
        : undefined;
      this.showGenericModal('rgpd');
      this.showGenericModal('accept-cgu');
      this.showGenericModal('quotas-student');
    },
    methods: {
      showFreeModal() {
        this.$modal.show('Gratuit!');
      },
      showDistanceModal() {
        this.$modal.show('A distance');
      },
      showGenericModal(name) {
        this.$modal.show(name);
      },
      closeGenericModal(name) {
        this.$modal.hide(name);
      },
      trackAhoy(action, targetId, targetType) {
        ahoy.track(action, {
          target_id: targetId,
          target_type: targetType,
          originator_id: parseInt(this.originatorId, 10),
          originator_Type: this.originatorType,
        });
      },
      setRating(rating) {
        this.rating = rating;
        document.querySelector('.rating-input').value = this.rating;
      },
      clearRating() {
        this.boundRating = 0;
        document.querySelector('.rating-input').value = this.boundRating;
      },
      async recaptchaAndCloseModal(name, form, modal) {
        await this.$recaptchaLoaded();

        const token = await this.$recaptcha(name);

        const captchaForm = document.getElementsByClassName(form)[0];

        const input = document.createElement('input');

        input.type = 'text';
        input.value = token;
        input.classList.add('hidden');
        input.name = 'capcha_token';

        captchaForm.append(input);
        captchaForm.submit();
        return modal === false ? false : this.closeGenericModal(modal);
      },
    },
  });
});
