<template>
  <v-select
    :options="options"
    :placeholder="placeholder"
    label="name"
    :multiple="multiple"
    :reduce="school => school.id"
    @search="fetchOptions"
    @input="getSchoolTokens"
  />
</template>
<script>
import axios from 'axios';

export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    elementReplaced: {
      type: String,
      required: false, 
      default: 'school-tokening',
    },
    multiple: {
      type: Boolean, 
      required: false,
      default: true,
    },
  },
  data() {
    return {
      options: [],
    };
  },
  methods: {
    fetchOptions(search, loading) {
      loading(true);
      axios.get('/schools_rep.json', {
        params: {
          q: search,
        },
      })
        .then((response) => {
          this.options = response.data;
          loading(false);
        });
    },
    getSchoolTokens(val) {
      const schoolElements = document.getElementById(this.elementReplaced);
      schoolElements.value = this.multiple ? val.join(',') : val;
    },
  },
};
</script>
<style scoped>
  .vs--single {
    font-size: 1.9rem;
    font-family: 'Work Sans', sans-serif;
  }

  .v-select {
    font-size: 1.9rem;
    font-family: 'Work Sans', sans-serif;
  }
</style>
