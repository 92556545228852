<template>
  <span>
    <div class="grow card__container text--center background--secondary margin-right--75">
      <break :size="9" />
      <div class="card__header flex flex--end cover">
        <break
          :size="9"
          direction="left"
        />
        <img
          src="/assets/seo/button-plus.png"
          style="height: 30px"
        >
      </div>
      <div class="card__content">
        <p class="text light text--white">
          Votre <slot name="sentence" /> ne figure pas dans notre liste ?
        </p>
      </div>
      <break :size="25" />
      <a
        href="https://pro.studyadvisor.fr"
        class="btn btn--primary btn--no-border btn--small"
      >
        référencez-vous ;-)
      </a>
    </div>
    <break :size="30" />
  </span>
</template>

<script>

export default {
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  methods: {
    showGenericModal(name) {
      this.$modal.show(name);
    },
  },
};

</script>

<style>
@media only screen and (max-width : 600px) {
   .margin-right--75 {
       margin-right: 0px !important;
   }
}

@media only screen and (width: 768px) {
  .margin-right--75 {
    margin-right: 50px !important;
  }
}

@media only screen and (max-width : 1200px) {
  .margin-right--75 {
    margin-right: 0px !important;
  }
}
  .margin-right--75 {
    margin-right: 75px;
  }

</style>
