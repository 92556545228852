<template>
  <div
    class="card grow card__container"
    :class="`card__container--${size} card__container--${width}`"
    :style="`margin-bottom: ${breakSize}px;`"
  >
    <div class="card__header">
      <div v-html="blog.image_tag" />
      <info-pill :text="blog.sponsored_type" :color="blog.sponsored_color" />
    </div>
    <div class="card__content">
      <h3 class="sub-title--small bolder text--spaced-bot">
        {{ blog.title }}
      </h3>
      <p
        v-sanitaize
        class="text text--footnote text--grey"
        v-html="blog.creation"
      />
    </div>
    <div class="flex flex--center">
      <button class="btn btn--primary btn--small bottom--spaced">
        <span class="text--smaller">En savoir plus</span>
      </button>
    </div>
    <a
      class="card__link--full "
      itemprop="url"
      :href="blog.url"
      @click="$root.trackAhoy('Click', blog.id, 'Blog')"
    />
  </div>
</template>

<script>
import ahoy from 'ahoy.js';
import colorTag from '../commodities/color_tag.vue';
import infoPill from '../commodities/info_pill.vue';

export default {
  components: {
    colorTag,
    infoPill,
  },
  props: {
    blog: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'short',
    },
    width: {
      type: String,
      required: false,
      default: '',
    },
    breakSize: {
      type: Number,
      required: false,
      default: 20,
    },
    track: {
      type: Boolean,
      required: false,
      default: false,
    },
    trackType: {
      type: String,
      required: false,
      default: 'Blog',
    },
    header: {
      type: Number,
      required: false,
      default: 3,
    },
  },
  mounted() {
    if (this.track === true) {
      ahoy.track('Display', {
        target_id: parseInt(this.id, 10),
        target_type: this.trackType,
      });
    }
  },
};
</script>

<style scoped>
.text,
.sub-title--small {
  word-break: normal !important;
}

.card__two-line-text {
  min-height: 50px;
}
</style>
