<template>
  <div 
    class="flex flex--center flex--vcenter logo__background"
  >
    <v-lazy-image
      :src="logo.logo"
      alt="partenaire"
      class="logo"
      :use-picture="logo.sources != undefined"
      v-html="logo.sources"
    />
  </div>
</template>

<script>
export default {
  props: {
    logo: {
      type: Object,
      required: true,
    },
  },
};
</script>


<style scoped>
  .logo {
    text-align: center;
    max-width: 160px;
  }

</style>
