<template>
  <p
    v-line-clamp:12="1"
    class="text--smaller bolder text--secondary"
    :style="getStyle()"
  >
    {{ text }}
  </p>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: '#D01A2A',
    },
  },
  methods: {
    getStyle() {
      return {
        'background-color': this.$props.color,
        padding: '3px 10px',
        height: '20px',
        'border-radius': '20px',
        border: `2px solid${this.$props.color}`,
        color: '#f5f8fb',
      };
    },
  },
};
</script>
