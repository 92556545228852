<template>
  <div class="flex flex--vcenter text--grey">
    <i :class="icon" />
    <break
      :size="10"
      direction="right"
    />
    <p class="text--smaller bold text--grey  flex--no-shrink">
      {{ label }} :
    </p>
    <break
      :size="10"
      direction="right"
      class="flex--shrink"
    />
    <p
      v-line-clamp:15="1"
      class="text--smaller text--thiner text--grey flex--shrink"
    >
      {{ value }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>
