<template>
  <v-select
    :options="options"
    :reduce="name => name.path"
    :placeholder="placeholder"
    label="name"
    @input="sendToVal($event)"
  />
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  methods: {
    sendToVal(val) {
      window.location.href = val;
    },
  },
};
</script>
<style scoped>
  .vs--single {
    font-size: 1.9rem;
    font-family: 'Work Sans', sans-serif;
  }
</style>
