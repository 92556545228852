<template>
  <div
    class="card card__container"
    :class="getSizeClass()"
  >
    <div
      class="card__header cover"
      :class="getHeaderClass()"
    >
      <div v-html="category.image" />
    </div>

    <div class="card__content">
      <h4
        v-if="header === 4"
        class="text text--close bold text--left"
      >
        {{ category.name }}
      </h4>
      <h3
        v-if="header === 3"
        class="text text--close bold text--left"
      >
        {{ category.name }}
      </h3>
      <h2
        v-if="header === 2"
        class="text text--close bold text--left"
      >
        {{ category.name }}
      </h2>
      <break :size="20" />
      <div
        v-sanitaize
        class="text text--grey text--left"
        v-html="category.description"
      />
    </div>
    <div class="card__gradient">
      <p class="card__extra-info text--footnote card__extra-info--left  text">
        {{ Number(category.sub_categories) + 1 }} contenus
      </p>
    </div>

    <a
      class="card__link--full"
      :href="category.url"
    />
  </div>
</template>

<script>

export default {
  props: {
     category: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
    header: {
      type: Number,
      required: false,
      default: 3,
    },
  },
  methods: {
    getSizeClass() {
      return `card__container--${this.$props.size}`;
    },
    getHeaderClass() {
      return `card__header--${this.$props.size}`;
    },
  },
};

</script>


<style scoped>
  .card {
    margin-bottom: 20px;
  }

</style>
