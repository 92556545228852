<template>
  <ais-panel>
    <template slot="header">
      <h3 class="text bold text--secondary">
        {{ refinement.title }}
      </h3>
    </template>
    <break :size="15" />
    <template slot="default">
      <ais-refinement-list
        :attribute="refinement.name"
        :operator="refinement.operator"
        :limit="refinement.limit"
        :show-more="refinement.show_more"
        :show-more-limit="refinement.show_more_limit"
        :transform-items="refinement.transform_string ? transformIt : undefined"
        :class-names="{
          'ais-RefinementList-showMore': 'algolia__show-more text'
        }"
      >
        <p
          slot="showMoreLabel"
          slot-scope="{ isShowingMore }"
        >
          {{ !isShowingMore ? 'Voir plus' : 'Voir moins' }}
        </p>
      </ais-refinement-list>
    </template>
  </ais-panel>
</template>
<script>
export default {
  props: {
    refinement: {
      type: Object,
      required: true,
    },
  },
  methods: {
    transformIt(items) {
      return items.map((item) => ({
        ...item,
        label: `${this.refinement.transform_string} +${item.label}`,
      }));
    },
  },
};
</script>
<style scoped>

</style>
