<template>
  <modal
    :name="name"
    height="auto"
    :adaptive="true"
  >
    <div class="modal-container">
      <div>
        <img
          :src="image"
          :alt="name"
        >
      </div>
      <div class="text">
        {{ text }}
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      message: 'Hello Vue!',
    };
  },
};
</script>


<style scoped>
  .modal-container {
    padding: 15px;
  }
  .text {
    font-family: 'Work Sans', sans-serif;
    color: #38383f;
    font-size: 16px;
    line-height: 2;
    font-weight: 500;
    margin-top: 15px;
    text-align: center;
  }
  img {
    max-width: 100%;
  }
</style>
