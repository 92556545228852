import { render, staticRenderFns } from "./select_navigation.vue?vue&type=template&id=5ff7fbbb&scoped=true"
import script from "./select_navigation.vue?vue&type=script&lang=js"
export * from "./select_navigation.vue?vue&type=script&lang=js"
import style0 from "./select_navigation.vue?vue&type=style&index=0&id=5ff7fbbb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff7fbbb",
  null
  
)

export default component.exports