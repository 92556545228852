<template>
  <div>
    <div class="flex flex--start flex--between flex--wrap">
      <div class="flex flex--start">
        <div style="z-index: 99;">
          <break :size="20" />
          <div
            class="timeline__dot"
            :style="'background-color: ' + education.color"
          />
        </div>

        <break
          v-if="$mq == 'phone'"
          :size="13"
          direction="right"
        />
        <break
          v-if="$mq != 'phone'"
          :size="23"
          direction="right"
        />
        <a
          v-if="education.in_site"
          :href="education.in_site"
        >
          <img
            v-if="education.image != undefined && ($mq != 'phone')"
            :src="education.image"
            alt=""
            class=" timeline__image"
          >
          <div
            v-if="education.image === undefined && ($mq != 'phone')"
            class="flex
              flex--vcenter
              flex--center
              timeline__image
              timeline__image--border
              title
              text--secondary"
          >
            <i class="fad fa-suitcase" />
          </div>
        </a>
        <div v-else>
          <img
            v-if="education.image != undefined && ($mq != 'phone')"
            :src="education.image"
            alt=""
            class=" timeline__image"
          >
          <div
            v-if="education.image === undefined && ($mq != 'phone')"
            class="flex
              flex--vcenter
              flex--center
              timeline__image
              timeline__image--border
              title
              text--secondary"
          >
            <i class="fad fa-suitcase" />
          </div>
        </div>

        <break
          v-if="$mq == 'phone'"
          :size="13"
          direction="right"
        />
        <break
          v-if="$mq != 'phone'"
          :size="23"
          direction="right"
        />
        <div class="timeline__text">
          <a
            v-if="education.in_site != false "
            :href="education.in_site"
            class="title--small bold"
          >
            <h5>
              {{ education.title }}
            </h5>
          </a>
          <h5
            v-else
            class="title--small bold"
          >
            {{ education.title }}
          </h5>
          <break :size="7" />
          <a
            v-if="education.program_in_site != false "
            :href="education.program_in_site"
            class="sub-title--small text--grey regular"
          >
            <h5>
              {{ education.subtitle }}
            </h5>
          </a>
          <h5
            v-else
            class="sub-title--small text--grey regular"
          >
            {{ education.subtitle }}
          </h5>
          <break :size="7" />
          <p class="text--smaller bold">
            {{ education.start_date }} — {{ education.end_date }}
          </p>
          <break :size="5" />
          <p class="text--smaller">
            {{ education.city }}
          </p>
          <break :size="5" />
          <div class="flex">
            <color-tag
              v-if="$mq == 'phone'"
              :color="education.color"
              :text="education.tag"
            />
          </div>
        </div>

        <break
          v-if="$mq == 'phone'"
          :size="13"
          direction="right"
        />
        <break
          v-if="$mq != 'phone'"
          :size="23"
          direction="right"
        />
      </div>
      <color-tag
        v-if="$mq != 'phone'"
        :color="education.color"
        :text="education.tag"
      />
    </div>
    <div v-if="education.concours">
      <break :size="50" />
      <div class="flex flex--start flex--between flex--wrap">
        <div class="flex flex--start">
          <div style="z-index: 99;">
            <break :size="20" />
            <div
              class="timeline__dot"
              :style="'background-color: ' + education.concours.color"
            />
          </div>

          <break
            v-if="$mq == 'phone'"
            :size="13"
            direction="right"
          />
          <break
            v-if="$mq != 'phone'"
            :size="23"
            direction="right"
          />
          <a
            v-if="education.concours.in_site"
            :href="education.concours.in_site"
          >
            <img
              v-if="education.concours.image != undefined && ($mq != 'phone')"
              :src="education.concours.image"
              alt=""
              class=" timeline__image"
            >
            <div
              v-if="education.concours.image === undefined && ($mq != 'phone')"
              class="flex
              flex--vcenter
              flex--center
              timeline__image
              timeline__image--border
              title
              text--secondary"
            >
              <i class="fad fa-suitcase" />
            </div>
          </a>
          <div v-else>
            <img
              v-if="education.concours.image != undefined && ($mq != 'phone')"
              :src="education.concours.image"
              alt=""
              class=" timeline__image"
            >
            <div
              v-if="education.concours.image === undefined && ($mq != 'phone')"
              class="flex
              flex--vcenter
              flex--center
              timeline__image
              timeline__image--border
              title
              text--secondary"
            >
              <i class="fad fa-suitcase" />
            </div>
          </div>

          <break
            v-if="$mq == 'phone'"
            :size="13"
            direction="right"
          />
          <break
            v-if="$mq != 'phone'"
            :size="23"
            direction="right"
          />
          <div class="timeline__text">
            <a
              v-if="education.concours.in_site != false "
              :href="education.concours.in_site"
              class="title--small bold"
            >
              <h5>
                {{ education.concours.title }}
              </h5>
            </a>
            <h5
              v-else
              class="title--small bold"
            >
              {{ education.concours.title }}
            </h5>
            <break :size="7" />
            <h5 class="sub-title--small text--grey regular">
              {{ education.concours.subtitle }}
            </h5>
            <break :size="7" />
            <p class="text--smaller bold">
              {{ education.concours.start_date }}
            </p>
            <break :size="5" />
            <a
              class="text--smaller"
              :href="education.concours.website"
            >
              {{ education.concours.tag_line }}
            </a>
            <break :size="5" />
            <div class="flex">
              <color-tag
                v-if="$mq == 'phone'"
                :color="education.concours.color"
                :text="education.concours.tag"
              />
            </div>
          </div>

          <break
            v-if="$mq == 'phone'"
            :size="13"
            direction="right"
          />
          <break
            v-if="$mq != 'phone'"
            :size="23"
            direction="right"
          />
        </div>
        <color-tag
          v-if="$mq != 'phone'"
          :color="education.concours.color"
          :text="education.concours.tag"
        />
      </div>
    </div>
    <break :size="50" />
  </div>
</template>
<script>

export default {
  props: {
    education: {
      type: Object,
      required: true,
    },
  },
};
</script>
