<template>
  <ais-instant-search
    index-name="Program"
    :search-client="searchClient"
  >
    <ais-configure
      :hits-per-page.camel="1000"
      :distinct="true"
      :filters="getFilters(school, question, exam)"
    />
    <ais-search-box
      placeholder="Recherche une formation"
      :autofocus="true"
      :show-loading-indicator="true"
      :class-names="{
        'ais-SearchBox-form': 'cover__search-bar-programs',
        'ais-SearchBox-input': 'search-bar search-bar__products',
        'ais-SearchBox-submit': 'btn btn--big btn--primary search-bar__button',
        'ais-SearchBox-reset': 'hidden'
      }"
    >
      <div slot="submit-icon">
        <i class="fas fa-search text--white fa-flip-horizontal" />
      </div>
    </ais-search-box>
    <ais-panel>
      <ais-hits>
        <div
          slot="default"
          slot-scope="{ items }"
          class="products"
        >
          <div class="flex flex--wrap flex--between">
            <div
              v-for="item in items"
              :key="item.objectID"
              class="school__small"
            >
              <school
                :establishment="item"
                :break-size="20"
                :track="true"
                track-type="Program"
              />
            </div>
          </div>
        </div>
      </ais-hits>
    </ais-panel>
  </ais-instant-search>
</template>

<script>
import algoliasearch from 'algoliasearch';

export default {
  props: {
    school: {
      type: Number,
      required: false,
      default: undefined,
    },
    question: {
      type: String,
      required: false,
      default: null,
    },
    exam: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      searchClient: algoliasearch(
        'RO0S8WBQLB',
        'fcfad034538a4be8d904384c344e6c65',
      ),
    };
  },
  methods: {
    getFilters(school, question, exam) {
      if (school !== undefined) {
        if (question === null) {
          return `school_id:${school}`;
        }
        return `school_id: ${school} AND question_names: '${question}'`;
      } if (exam !== undefined) {
        return `competitive_exam_ids:${exam}`;
      }
      return '';
    },
  },
};
</script>

<style scoped>

.cover__search-bar-programs {
  position: relative;
  display: inline-block
}

.products {
  width: 800px;
  margin-top: 40px;
}

@media only screen and (max-width : 1200px) {
  .products {
    width: 800px;
  }
}

@media only screen and (max-width : 800px) {
  .products {
    width: 525px;
  }
}

@media only screen and (max-width : 600px) {
  .products {
    width: 100%;
  }
  .products .flex {
    justify-content: center;
  }
}

.search-bar__button {
  top: 0;
}

.flex--around {
  width: 80%;
  margin-right: auto;
  margin-left: auto
}

</style>
