<template>
  <div>
    <div
      class="card grow card__container text--center"
      style="position: relative"
      :class="`card__container--${type} card__container--${width}`"
    >
      <div
        class="badge--top-advisor-full"
        v-if="counselor.top_advisor"
      >
        <img
          src="https://studyadv.s3.eu-west-1.amazonaws.com/production/counselors/ranks/Top+Advisor.png"
          alt="médaille"
        />
        <p>Top Advisor</p>
      </div>
      <div
          class="badge--top-advisor-full"
          v-if="counselor.partenaire"
      >
        <img
            src="https://studyadv.s3.eu-west-1.amazonaws.com/production/counselors/ranks/Profil+ADV.svg"
            alt="médaille"
        />
        <p>Partenaire</p>
      </div>
      <div
        v-if="counselor.new_advisor"
      >
        <p class="badge--new-advisor-full">New</p>
      </div>
      <div
        class="card__header flex flex--end cover card__header--search"
        v-html="counselor.cover_tag + counselor.picture_tag"
      />
      <div class="card__content">
        <break :size="50" />
        <p class="sub-title--small bolder text--center">
          {{ counselor.first_name }}, {{ counselor.age }}
        </p>
        <break :size="10" />
        <div v-if="ratings != 0" class="flex flex--center">
          <div class="stars">
            <div
              v-for="(star, index) in stars"
              :key="`${index}-${counselor.id}`"
            >
              <i
                v-if="star == 'full'"
                class="fas fa-star stars__star stars__star--yellow"
                aria-hidden="true"
              />
              <i
                v-if="star == 'half'"
                class="fas fa-star-half-alt stars__star stars__star--yellow"
                aria-hidden="true"
              />
              <i
                v-if="star == 'empty'"
                class="far fa-star stars__star stars__star--yellow"
                aria-hidden="true"
              />
            </div>
          </div>
          <break direction="right" :size="10" />
          <p class="text--small light text--grey">({{ ratings > 0 ? ratings : "" }})</p>
        </div>
        <div v-else>
          <div class="stars">
            <p class="text--small text--white">.</p>
          </div>
        </div>
        <break :size="15" />
        <h2
          v-if="header === 2"
          v-line-clamp:34="2"
          class="sub-title--small bold text--secondary card__two-line-text"
        >
          {{ counselor.educations[0].school }}
        </h2>
        <h3
          v-if="header === 3"
          v-line-clamp:34="2"
          class="sub-title--small bold text--secondary card__two-line-text"
        >
          {{ counselor.educations[0].school }}
        </h3>
        <h4
          v-if="header === 4"
          v-line-clamp:34="2"
          class="sub-title--small bold text--secondary card__two-line-text"
        >
          {{ counselor.educations[0].school }}
        </h4>
        <h5
          v-if="header === 5"
          v-line-clamp:34="2"
          class="sub-title--small bold text--secondary card__two-line-text"
        >
          {{ counselor.educations[0].school }}
        </h5>
        <break :size="5" />
        <h2
          v-if="header === 2"
          v-line-clamp:16="1"
          class="text light text--grey"
        >
          {{ counselor.educations[0].program }}
        </h2>
        <h3
          v-if="header === 3"
          v-line-clamp:16="1"
          class="text light text--grey"
        >
          {{ counselor.educations[0].program }}
        </h3>
        <h4
          v-if="header === 4"
          v-line-clamp:16="1"
          class="text light text--grey"
        >
          {{ counselor.educations[0].program }}
        </h4>
        <h5
          v-if="header === 5"
          v-line-clamp:16="1"
          class="text light text--grey"
        >
          {{ counselor.educations[0].program }}
        </h5>
        <break :size="15" />
        <div class="flex flex--center">
          <color-tag
            v-if="counselor.question"
            :color="counselor.question.color"
            :text="counselor.question.name"
          />
        </div>
        <break :size="10" />
        <p class="text light text--grey">
          {{ counselor.study_level }}
        </p>
        <break :size="15" />
        <div
          v-if="counselor.educations[1] && type == 'search'"
          class="margin-bottom"
        >
          <p class="text bold text--grey">Ma formation précédente</p>
          <break :size="5" />
          <p v-line-clamp:48="3" class="text light text--grey">
            {{ counselor.educations[1].school
            }}{{
              counselor.educations[1].program === ''
                ? ''
                : `, ${counselor.educations[1].program}`
            }}
          </p>
        </div>
        <div class="card__hover card__hover--brand">
          <break :size="5" />
          <p class="text blod text--white">Découvrir</p>
        </div>
        <a
          :href="counselor.path"
          class="card__link--full"
          @click="$root.trackAhoy('Click', parseInt(counselor.id), 'Counselor')"
        />
      </div>
    </div>
    <break :size="breakSize" />
  </div>
</template>

<script>
import axios from 'axios';
import ahoy from 'ahoy.js';

export default {
  props: {
    counselor: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: undefined,
    },
    type: {
      type: String,
      required: false,
      default: 'complete',
    },
    width: {
      type: String,
      required: false,
      default: '',
    },
    breakSize: {
      type: Number,
      required: false,
      default: 10,
    },
    track: {
      type: Boolean,
      required: false,
      default: false,
    },
    trackType: {
      type: String,
      required: false,
      default: undefined,
    },
    header: {
      type: Number,
      required: false,
      default: 4,
    },
  },
  data() {
    return {
      ratings: 0,
      stars: [],
      confirmed_at: '',
    };
  },
  mounted() {
    axios
      .get(
        `/counselors/${
          this.$props.id ? this.$props.id : this.$props.counselor.id
        }/get_grade.json`
      )
      .then((response) => {
        const stars = response.data.grade / 20.0;
        let i;
        for (i = stars; i > 0; i -= 1) {
          if (i > 0.5) {
            this.stars.push('full');
          } else {
            this.stars.push('half');
          }
        }
        for (i = 5 - stars; i >= 1; i -= 1) {
          this.stars.push('empty');
        }
        this.ratings = response.data.ratings;
      });
    if (this.track === true) {
      ahoy.track('Display', {
        target_id: parseInt(this.id, 10),
        target_type: this.trackType,
      });
    }
  },
  methods: {
    getCoverPath() {
      return this.counselor.cover_url.charAt(0) !== 'b'
        ? this.counselor.cover_url
        : `/assets/${this.counselor.cover_url}`;
    },
  },
};
</script>

<style scoped>
.text,
.sub-title--small {
  word-break: normal !important;
}
.margin--bottom {
  margin-bottom: 45px;
}
</style>
