<template>
  <div class="wrapper">
    <div
      class="card
      grow card__container
      text--center
      card__container--horizontal
      flex
      flex--around
      flex--vcenter
      flex--wrap"
      style="position: relative"
    >
      <div class="card__logo-container--horizontal flex flex--center flex--vcenter">
        <img
          :src="counselor.picture"
          :alt="counselor.first_name"
          class="card__logo card__logo--no-margin"
        >
      </div>
      <div class="card__information-container--horizontal text--left">
        <div class="flex flex--wrap">
          <h3 class="sub-title--small bolder">
            {{ counselor.first_name }}, {{ counselor.age }}
          </h3>
          <break
            direction="right"
            :size="10"
          />
          <div class="flex flex--center">
            <div class="stars">
              <div
                v-for="(star, index) in stars"
                :key="`${index}-${counselor.id}`"
              >
                <i
                  v-if="star == 'full'"
                  class="fas fa-star stars__star stars__star--yellow"
                  aria-hidden="true"
                />
                <i
                  v-if="star == 'half'"
                  class="fas fa-star-half-alt stars__star stars__star--yellow"
                  aria-hidden="true"
                />
                <i
                  v-if="star == 'empty'"
                  class="far fa-star stars__star stars__star--yellow"
                  aria-hidden="true"
                />
              </div>
            </div>
            <break
              direction="right"
              :size="10"
            />
            <p class="text--small light text--grey">
              ({{ ratings > 0 ? ratings : ""}})
            </p>
          </div>
        </div>
        <break :size="20" />
        <p
          v-line-clamp:38="2"
          class="text bold text--grey card__two-line-text"
        >
          {{ counselor.educations[0].school }}
        </p>
        <break :size="5" />
        <p
          v-line-clamp:38="2"
          class="text thinner text--grey card__two-line-text"
        >
          {{ counselor.educations[0].program }}
        </p>
        <break :size="5" />
      </div>
      <div class="card__information-container--horizontal">
        <div class="flex flex--center">
          <color-tag
            v-if="counselor.question"
            :color="counselor.question.color"
            :text="counselor.question.name"
          />
        </div>
        <break :size="25" />
        <div v-if="counselor.educations[1]">
          <p class="text bold text--grey">
            Ma formation précédente
          </p>
          <break :size="5" />
          <p
            v-line-clamp:48="3"
            class="text light text--grey"
          >
            {{ counselor.educations[1].school }}{{ counselor.educations[1].program === ""
              ? ""
              : `, ${counselor.educations[1].program}` }}
          </p>
        </div>
      </div>
      <div class="card__hover card__hover--brand">
        <break :size="6" />
        <p class="text blod text--white">
          Découvrir
        </p>
      </div>
      <a
        :href="counselor.path"
        class="card__link--full"
        @click="$root.trackAhoy('Click', parseInt(counselor.id), 'Counselor')"
      />
    </div>
    <break :size="breakSize" />
  </div>
</template>

<script>

import axios from 'axios';
import ahoy from 'ahoy.js';
import colorTag from '../commodities/color_tag.vue';


export default {
  components: {
    colorTag,
  },
  props: {
    counselor: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: undefined,
    },
    breakSize: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  data() {
    return {
      ratings: 0,
      stars: [],
    };
  },
  mounted() {
    axios.get(`/counselors/${this.$props.id ? this.$props.id : this.$props.counselor.id}/get_grade.json`)
      .then((response) => {
        const stars = response.data.grade / 20.0;
        let i;
        for (i = stars; i > 0; i -= 1) {
          if (i > 0.5) {
            this.stars.push('full');
          } else {
            this.stars.push('half');
          }
        }
        for (i = (5 - stars); i >= 1; i -= 1) {
          this.stars.push('empty');
        }
        this.ratings = response.data.ratings;
      });
    if (this.track === true) {
      ahoy.track('Display', {
        target_id: parseInt(this.id, 10),
        target_type: this.trackType,
      });
    }
  },
};

</script>

<style scoped>
  .text,
  .sub-title--small {
    word-break: normal !important;
  }

  .wrapper {
    width: 100%;
  }
</style>
