<template>
  <div :style="getStyle()" />
</template>
<script>
export default {
  props: {
    direction: {
      type: String,
      default: 'top',
    },
    size: {
      type: Number,
      default: 25,
    },
  },
  methods: {
    getStyle() {
      return `margin-${this.direction}: ${this.size}px`;
    },
  },
};
</script>
