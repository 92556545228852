<template>
  <a :href="url" target="_blank">
    <img v-bind:src="getImage()" :alt="`icone ${name}`" />
  </a>
</template>
<script>
import linkedinImage from '../../../assets/images/icons/linkedin-in-icon';
import facebookImage from '../../../assets/images/icons/facebook-f-icon';
import twitterImage from '../../../assets/images/icons/twitter-icon';
export default {
  data() {
    return {
      linkedinImage,
      facebookImage,
      twitterImage,
    };
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    getStyle() {
      return {
        color: this.color,
        'font-size': `${this.size}px`,
      };
    },

    getImage() {
      if (this.name === 'linkedin-in') {
        return linkedinImage;
      } else if (this.name === 'facebook-f') {
        return facebookImage;
      } else if (this.name === 'twitter') {
        return twitterImage;
      }
    },
  },
};
</script>
