<template>
  <div
    class="site_video"
  >
    <i
      class="material-icons manipulation video__play-button"
      :class="bigIcon ? 'video__play-button--big' : ''"
      @click="openVideoModal"
    >
      play_circle_filled
    </i>
    <img
      v-if="thumb === undefined || thumb === '/assets/default_avatar.jpeg'"
      :src="'https://img.youtube.com/vi/' + getId() + '/hqdefault.jpg'"
      :alt="alt"
      class="manipulation grow"
      :class="shadow ? 'shadow' : ' school_item--shadow '"
      @click="openVideoModal"
    >
    <img
      v-if="thumb != undefined && thumb != '/assets/default_avatar.jpeg'"
      :src="thumb"
      :alt="alt"
      class="manipulation grow"
      :class="shadow ? 'shadow' : ' school_item--shadow '"
      @click="openVideoModal"
    >

    <modal
      :name="alt"
      :width="$mq == 'tablet' || $mq == 'phone' ? '85%' : '45%'"
      :height="'auto'"
      :adaptive="true"
      classes="v--modal modal--tablet-full"
      @opened="playVideo"
    >
      <div class="video-container">
        <youtube
          ref="youtube"
          :video-id="getId()"
          :fit-parent="true"
        />
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      type: String,
      required: true,
    },
    thumb: {
      type: String,
      required: false,
      default: undefined,
    },
    alt: {
      type: String,
      required: true,
    },
    bigIcon: {
      type: Boolean,
    },
    shadow: {
      type: Boolean,
    },
  },
  methods: {
    getId() {
      return this.$youtube.getIdFromUrl(this.video);
    },
    openVideoModal() {
      this.$modal.show(this.alt);
    },
    playVideo() {
      this.$refs.youtube.player.playVideo();
    },
  },
};
</script>


<style scoped>
  .video-container {
    width: 45vw;
  }
    @media only screen and (max-width : 800px) {
      .video-container {
        width: 85vw
      }
    }

  img {
    width: 100%;
    border-radius: 10px;
    filter: opacity(90%);
  }

  .site_video {
    width: 100%;
  }

  .video__play-button {
    color: #FFF;
    opacity: .75;
    position: absolute;
    top: calc(50% - 35px);
    font-size: 70px;
    left: calc(50% - 35px);
    z-index: 99
  }

  .video__play-button--big {
    top: calc(50% - 125px);
    font-size: 250px;
    left: calc(50% - 125px)

  }

  @media only screen and (max-width : 800px) {
    .video__play-button--big {
      top: calc(50% - 35px);
      font-size: 70px;
      left: calc(50% - 35px);
    }
  }

</style>
