// Component renders a linelike object.
// accepts props for direction of the line, the size and width, color and border radius
// can also be used to rendre dots if width and size are the same and border radius is 100%.
<template>
  <div
    :style="getStyle()"
  />
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      required: false,
      default: 'vertical',
    },
    size: {
      type: String,
      required: false,
      default: '100%',
    },
    width: {
      type: String,
      required: false,
      default: '1px',
    },
    color: {
      type: String,
      required: false,
      default: '#dde0e5',
    },
    radius: {
      type: String,
      default: '0px',
    },
  },
  methods: {
    getStyle() {
      switch (this.direction) {
        case 'vertical':
          return {
            height: this.size,
            backgroundColor: this.color,
            // counterintuitive. In this case width refers to the
            // `font size` of the line
            width: this.width,
            borderRadius: this.radius,
          };
        case 'horizontal':
          return {
            width: this.size,
            backgroundColor: this.color,
            // counterintuitive. In this case width refers to the
            // `font size` of the line
            height: this.width,
            borderRadius: this.radius,

          };
        default:
          return null;
      }
    },
  },
};
</script>

<style>

</style>
