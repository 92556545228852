// Component work with modal-image // accepts props for images, image and alt
<template>
  <picture>
    <source :srcset="webpizeImage(image.image)" type="image/webp" />
    <source :srcset="image.image" />
    <img
      :src="image.image"
      :alt="alt"
      class="school_item--shadow grow manipulation site-image__image"
      @click="openImageModal(image.image)"
    />
  </picture>
  <!-- </div> -->
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
  // when modal is opened, it sends 3 params
  // image, alt, and images
  methods: {
    openImageModal(image) {
      this.$modal.show("modal-image", { image: this.image, alt: this.alt });
    },
    webpizeImage(image) {
      const segments = image.replace("small", "small_webp").split(".");
      segments.pop();
      return `${segments.join(".")}.webp`;
    },
  },
};
</script>

<style scoped></style>
