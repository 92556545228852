<template>
  <div class="flex flex--center flex--vcenter">
    <timeselector
      return-format="HH"
      :name="time"
      :placeholder="'hh:mm ⏱'"
    />
  </div>
</template>


<script>
import Timeselector from 'vue-timeselector';

export default {

  components: {
    Timeselector,
  },
  data() {
    return {
      time: new Date().toString(),
    };
  },
};
</script>
<style>
  .vtimeselector__input {
    border: none
  }

  .timeselector__box__item--is-selected {
    background: #61ce77;
    color: #ffffff;
  }
  .vtimeselector__input {
    height: 40px;
    border-radius: 5px;
    border: 0.5px solid rgba(161, 166, 175, 0.6);
    font-size: 18px;
    font-size: 1.8rem;
    background-color: #fff;
    color: #666C78;
    padding: 0 10px;
    max-width: 100%;
  }
  .vtimeselector__box__item {
    padding: .4em 0;
    font-size: 1.5em;
  }
</style>
