<template>
  <ais-instant-search index-name="School" :search-client="searchClient">
    <ais-configure
      :hits-per-page.camel="6"
      :distinct="true"
      :filters="getFilters(categoryNames, subCategoryNames, city)"
    />
    <ais-search-box
      placeholder="Recherche une formation"
      :autofocus="true"
      :show-loading-indicator="true"
      :class-names="{
        'ais-SearchBox-form': 'cover__search-bar-programs',
        'ais-SearchBox-input': 'search-bar search-bar__products',
        'ais-SearchBox-submit': 'btn btn--big btn--primary search-bar__button',
        'ais-SearchBox-reset': 'hidden',
      }"
    >
      <div slot="submit-icon">
        <i class="fas fa-search text--white fa-flip-horizontal" />
      </div>
    </ais-search-box>
    <ais-panel>
      <ais-hits>
        <div slot="default" slot-scope="{ items }" class="products">
          <div class="flex flex--wrap flex--between">
            <div
              v-for="item in items"
              :key="item.objectID"
              class="school__small"
            >
              <school
                :establishment="item"
                :break-size="20"
                :track="true"
                track-type="Program"
              />
            </div>
          </div>
        </div>
      </ais-hits>
      <ais-pagination>
        <ul
          class="pagination"
          slot-scope="{
            currentRefinement,
            nbPages,
            pages,
            isFirstPage,
            isLastPage,
            refine,
            createURL,
          }"
        >
          <li v-if="!isFirstPage" class="list-link">
            <p class="link-text">
              Précédent
            </p>
            <a
              class="link link--option"
              :href="createURL(currentRefinement - 1)"
              @click.prevent="
                refine(currentRefinement - 1);
                delayToTop();
              "
            >
            </a>
          </li>
          <li
            v-for="page in $mq == 'tablet' || $mq == 'phone'
              ? pages.slice(
                  currentRefinement == 0
                    ? currentRefinement
                    : currentRefinement - 1,
                  currentRefinement == 0
                    ? currentRefinement + 3
                    : currentRefinement + 1
                )
              : pages"
            :key="page"
            :style="{
              backgroundColor: page === currentRefinement ? '#868c98' : '',
            }"
            class="list-link"
          >
            <p
              class="link-text"
              :style="{
                fontWeight: page === currentRefinement ? 'bold' : '',
                color: page === currentRefinement ? '#fff' : '',
              }"
            >
              {{ page + 1 }}
            </p>
            <a
              class="link link--option"
              :href="createURL(page)"
              @click.prevent="
                refine(page);
                delayToTop();
              "
            >
            </a>
          </li>
          <li v-if="!isLastPage" class="list-link">
            <p class="link-text">
              Suivant
            </p>
            <a
              class="link link--option"
              :href="createURL(currentRefinement + 1)"
              @click.prevent="
                refine(currentRefinement + 1);
                delayToTop();
              "
            >
            </a>
          </li>
        </ul>
      </ais-pagination>
    </ais-panel>
  </ais-instant-search>
</template>

<script>
import algoliasearch from "algoliasearch";

export default {
  props: {
    categoryNames: {
      type: String,
      required: false,
      default: undefined,
    },
    subCategoryNames: {
      type: String,
      required: false,
      default: null,
    },
    city: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      searchClient: algoliasearch(
        "RO0S8WBQLB",
        "fcfad034538a4be8d904384c344e6c65"
      ),
    };
  },
  methods: {
    getFilters(categoryNames, subCategoryNames, city) {
      let array = [];
      categoryNames ? array.push(`category_names:'${categoryNames}'`) : null;
      subCategoryNames
        ? array.push(`sub_category_names:'${subCategoryNames}'`)
        : null;
      city ? array.push(`city:'${city}'`) : null;

      console.log(array.join(" AND "));
      return array.join(" AND ");
    },
  },
};
</script>

<style scoped>
.cover__search-bar-programs {
  position: relative;
  display: inline-block;
}

.products {
  width: 800px;
  margin-top: 40px;
}

ul.pagination {
  list-style-type: none;
  font-size: 0;
}
.link-text {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
}
ul.pagination li {
  display: inline-block;
  border-left: 1px solid #a1a6af;
  border-top: 1px solid #a1a6af;
  border-bottom: 1px solid #a1a6af;
  position: relative;
  padding: 10px 15px;
}
ul.pagination li:first-child {
  border-radius: 5px 0 0 5px;
  background-color: #084663;
  color: white;
}
ul.pagination li:last-child {
  border-right: 1px solid #a1a6af;
  border-radius: 0 5px 5px 0;
  background-color: #084663;
  color: white;
}
ul.pagination li a {
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.search-bar__button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  line-height: 30px;
}
.mobile__facets-menu {
  position: absolute;
  padding: 0 20px;
  top: 44px;
  left: 0;
  width: 560px;
  z-index: 199;
  background-color: #fff;
}
@media only screen and (max-width: 600px) {
  .mobile__facets-menu {
    width: auto;
    left: 0;
  }
}
@media only screen and (max-width: 350px) {
  .mobile__facets-menu {
    width: auto;
    left: 0;
  }
}
html,
body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-family: "Work Sans", sans-serif;
  height: 100%;
}
#root,
.ais-InstantSearch {
  height: 100%;
}
/*
  * Header
  */
.navbar {
  background-color: #222f3f;
  display: flex;
  align-items: center;
  padding: 10px;
}
.navbar__title {
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
  margin: 0 15px;
}
/*
  * SearchBox
  */
.navbar .ais-SearchBox-form {
  display: flex;
}
.navbar .ais-SearchBox-input {
  width: 275px;
  padding: 5px 12px;
  border: 1px solid transparent;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
}
.navbar .ais-SearchBox-input:focus {
  border-color: #ffbe61;
  outline: none;
}
.navbar .ais-SearchBox-submit {
  position: initial;
  background-color: #ffbe61;
  padding: 0 30px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  width: initial;
  height: initial;
  top: initial;
  right: initial;
  transform: initial;
}
.navbar .ais-SearchBox-submitIcon {
  width: 15px;
  height: 15px;
  position: initial;
  top: initial;
  left: initial;
  transform: initial;
}
.navbar .ais-SearchBox-reset {
  display: none;
}
.content-wrapper {
  display: flex;
  height: calc(100% - 60px);
}
.algolia__show-more {
  background: none;
  color: #a1a6af;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}
/*
  * Aside
  */
aside {
  border-right: 1px solid #eee;
  padding: 20px;
  flex: 0 0 20em;
  max-width: 240px;
}
.ais-ClearRefinements-button {
  display: block;
  margin-bottom: 16px;
  font-size: 13px;
  background-color: white;
  border: 1px solid lightgray;
  color: #000000;
}
.facet-wrapper {
  padding-bottom: 12px;
  border-bottom: solid 1px #eee;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  width: 200px;
  transition: all 2s ease-out;
}

.facet-title {
  font-size: 1.2em;
  color: #888;
  margin-bottom: 16px;
}
.ais-Panel-header {
  margin: 4px 0;
  clear: both;
  padding: initial;
  border-bottom: none;
}
.ais-Panel-header h5 {
  font-weight: bold;
  text-transform: none;
  font-size: 13px;
  margin: 10px 0;
  color: #333;
}
aside a,
.ais-HierarchicalMenu-link {
  color: #000000;
}
.ais-HierarchicalMenu-item {
  margin-top: 0px;
  font-size: 1.6em;
}
.ais-HierarchicalMenu-item:after {
  content: none;
}
.ais-HierarchicalMenu-link:hover,
.ais-RatingMenu-link:hover {
  color: #b32500;
  text-decoration: none;
}
.ais-HierarchicalMenu-item--selected > a,
.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent > a {
  font-weight: bold;
}
.ais-HierarchicalMenu-link {
  margin-right: 4px;
}
.ais-HierarchicalMenu-label:before {
  content: "> ";
}
.ais-HierarchicalMenu-list--child {
  margin-left: 10px;
}
.ais-RefinementList-label {
  font-weight: 100;
  color: #000000;
}
.ais-RefinementList-checkbox {
  margin-right: 5px;
}
.ais-RefinementList-count,
.ais-HierarchicalMenu-count {
  color: #6f6e6c;
  background-color: initial;
}
.ais-RefinementList-searchBox .ais-SearchBox-input {
  padding: 0.3em 2em;
}
.ais-RangeInput-input--min,
.ais-RangeInput-input--max {
  width: 50px;
  border-radius: 2px;
  border: solid 1px #888;
  font-weight: normal;
  font-size: 12px;
  padding: 0px;
  margin: 0px;
}
.ais-RangeInput-separator {
  margin: 0px 5px;
}
.ais-RangeInput-submit {
  display: block;
  float: right;
  width: 22px;
  height: 22px;
  padding: 0;
  margin-left: 6px;
  font-size: 10px;
  line-height: 20px;
  border: solid 1px #888;
  border-radius: 50%;
  text-align: center;
  background: none;
  color: #000000;
}
.ais-RangeInput-submit:hover {
  color: #0063c3;
  background-color: #ffffff;
}
.ais-RatingMenu-label,
.ais-RatingMenu-item--selected .ais-RatingMenu-label {
  color: #000000;
}
.ais-RatingMenu-item--selected .ais-RatingMenu-label {
  font-weight: 700;
}
.ais-RatingMenu-count {
  color: #6f6e6c;
  background-color: initial;
}
/*
  * Results
  */
.results {
  flex: 1;
  flex-direction: column;
}
.results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-top: 10px;
}
.products {
  margin: 10px 5px 40px 5px;
  max-width: 1110px;
}
@media only screen and (max-width: 800px) {
  .products {
    margin: 10px 0 40px 0;
  }
}
.products-no-results {
  text-align: center;
  margin: 25px 0;
  font-size: 1.2em;
}
.product {
  width: 24%;
  float: left;
  padding: 10px 20px 20px;
  margin-bottom: 10px;
  border-bottom: solid 1px #eee;
  margin: 0.5%;
  border: solid 1px #eee;
  box-shadow: 0 0 3px #f6f6f6;
  position: relative;
}
.product-picture-wrapper {
  text-align: center;
  margin-bottom: 10px;
}
.product-picture {
  height: 150px;
  max-width: 100%;
}
.product-desc-wrapper {
  height: 125px;
  width: 100%;
  overflow: hidden;
}
.product-name {
  font-weight: bold;
  color: #000000;
  font-size: 0.9em;
  margin: 0 0 8px;
  min-width: 120px;
}
.product-brand {
  font-size: 0.8em;
  margin: 0 0 10px;
  color: #a2a2a2;
}
.product-footer {
  display: flex;
  justify-content: space-between;
}
.product-price {
  font-weight: bold;
  color: #000000;
}
.ais-RatingMenu-starIcon {
  width: 15px;
  fill: #ffc168;
}
.ais-RatingMenu-starIcon {
  width: 15px;
  fill: #ffc168;
}
.ais-Pagination {
  margin: 5px 0 15px;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .products {
    width: 800px;
  }
}

@media only screen and (max-width: 800px) {
  .products {
    width: 525px;
  }
}

@media only screen and (max-width: 600px) {
  .products {
    width: 100%;
  }
  .products .flex {
    justify-content: center;
  }
}

.search-bar__button {
  top: 0;
}

.flex--around {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}
</style>
