<template>
  <GmapMap
    :center="{lng:2.213749, lat:47.227638}"
    :zoom="5"
    map-type-id="terrain"
    class="map__full"
  >
    <GmapMarker
      v-for="(m, index) in markers"
      :key="index"
      :position="m.position"
      :clickable="true"
      :draggable="false"
      title="test"
      @click="openInfoWindowTemplate(markers[index])"
    />
    <gmap-info-window
      :options="{maxWidth: 300}"
      :position="infoWindow.position"
      :opened="infoWindow.open"
      @closeclick="infoWindow.open=false"
    >
      <div
        v-sanitaize
        v-html="infoWindow.template"
      />
    </gmap-info-window>
  </GmapMap>
</template>
<script>
export default {
  props: {
    markers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        template: '',
      },
    };
  },
  methods: {
    getCoordinates(innerItem) {
      return {
        lng: innerItem.position.lng,
        lat: innerItem.position.lat,
      };
    },
    openInfoWindowTemplate(item) {
      const { info_window, position } = item;
      this.infoWindow.position = this.getCoordinates(item);
      this.infoWindow.template = info_window;
      this.infoWindow.open = true;
      this.center = position;
    },
  },
};
</script>
