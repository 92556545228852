<template>
  <div class="tag--spaced flex flex--vcenter flex--center relative">
    <p
      v-line-clamp:20="1"
      class="text tag"
    >
      {{ text }}
    </p>
    <a
      v-if="link != undefined"
      :href="link"
      class="card__link--full"
    />
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false,
      default: undefined,
    },
  },
};
</script>
