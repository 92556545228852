<template>
  <div
    class="flash flex flex--between flex--vcenter"
    :class="getClass()"
  >
    <p class="text">
      {{ value }}
    </p>
    <p
      class="title manipulation bold"
      @click="hideFlash(this)"
    >
      x
    </p>
  </div>
</template>

<script>
export default {
  props: {
    alertType: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.hideFLashLater();
  },
  methods: {
    getClass() {
      if (this.alertType === 'alert') {
        return 'flash--danger';
      } if (this.alertType === 'notice') {
        return 'flash--cool';
      }
      return 'flash--warning';
    },
    hideFlash() {
      this.$el.classList.add('hidden');
    },
    hideFLashLater() {
      setTimeout(() => {
        this.hideFlash();
      }, 10000);
    },
  },
};
</script>
