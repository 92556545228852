<template>
  <scroll-container @change="updateState">
    <scroll-item :id="'item'">
      <div
        class="community__card community__card--small flex flex--vcenter flex--around flex--column"
        :style="`background-color: #` + color + `;`"
      >
        <div class="title title--big text--white bold">
          <animated-number
            :value="number"
            :duration="1700"
            :delay="delay"
            :round="round"
            :format-value="toSpacesNumber"
          />
          <span class="text--small">
            {{ numberText }}
          </span>
          <span v-if="total">/ {{ total }}</span>
        </div>
        <div class="text bold text--white">
          {{ text }}
        </div>
      </div>
    </scroll-item>
  </scroll-container>
</template>

<script>
import { ScrollContainer, ScrollItem } from 'vue-scrollmonitor';
import AnimatedNumber from 'animated-number-vue';

export default {
  components: {
    ScrollContainer,
    ScrollItem,
    AnimatedNumber,
  },
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    color: {
      type: String,
      required: false,
      default: 'FFFFFF',
    },
    endNumber: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: false,
      default: undefined,
    },
    round: {
      type: Number,
      required: false,
      default: 1,
    },
    delay: {
      type: Number,
      required: false,
      default: 0,
    },
    numberText: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      state: {},
      number: 0,
    };
  },
  watch: {
    state() {
      if (this.$data.state.item.isFullyInViewport) {
        this.$data.number = this.$props.endNumber;
      }
    },
  },
  methods: {
    updateState(state) {
      this.state = state;
    },
    toSpacesNumber(value) {
      return value.toLocaleString('fr-FR');
    },
  },
};
</script>


<style scoped>

</style>
