<template>
  <div class="flex flex--wrap flex--around">
    <category-card
      :category="categories[0]"
      size="medium"
    />
    <category-card
      :category="categories[1]"
      size="medium"
    />
    <category-card
      :category="categories[2]"
      size="medium"
    />
    <mq-layout :mq="['small_cpu', 'tablet', 'phone']">
      <category-card
        :category="categories[3]"
        size="medium"
      />
    </mq-layout>
  </div>
</template>

<script>

export default {
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
};
</script>


<style scoped>


</style>
