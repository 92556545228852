<template>
  <v-lazy-image
    :src="src"
    :alt="alt"
    :class="className"
  />
</template>

<script>
import VLazyImage from 'v-lazy-image';

export default {
  components: {
    VLazyImage,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
  },
};

</script>
