<template>
  <div class="">
    <datepicker
      v-model="beginDate"
      class="flex flex--center flex--vcenter input__inner "
      style="width:205px"
      :disabled-dates="disabledDates"
      name="date"
      @selected="recieve"
      @load="recieve"
    />
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
// eslint-disable-next-line no-unused-vars
import { fr } from 'vuejs-datepicker/dist/locale';

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      beginDate: Date.now(),
      DatePickerFormat: 'dd/MM/yyyy',
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
      },
    };
  },
  methods: {
    recieve(date) {
      return date;
    },
  },
};
</script>
<style>
  .vdp-datepicker * {
    border: none;
    font-size: 18px;
    font-size: 1.8rem;
    background-color: #fff;
    color: #666C78;
  }
  .vdp-datepicker header{
    height: auto !important;
  }


</style>
