<template>
  <modal
    :name="'modal-image'"
    :width="$mq == 'tablet' || $mq == 'phone' ? '85%' : '45%'"
    :height="'auto'"
    :adaptive="true"
    :click-to-close="true"
    classes="v--modal modal--tablet-full"
    @before-open="beforeOpen"
  >
    <carousel
      :per-page="1"
      :navigation-enabled="true"
      :navigate-to="navigateTo"
      class="image-container flex flex--center"
    >
      <break :size="40" />
      <slide
        v-for="pic in images"
        :key="pic.id"
        class="flex flex--center flex--vcenter"
      >
        <picture>
          <source :srcset="webpizeImage(pic.image_full)" type="image/webp" />
          <source :srcset="pic.image_full" />
          <img :src="pic.image_full" :alt="alt" class="center" />
        </picture>
      </slide>
    </carousel>
  </modal>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      navigateTo: 0,
      alt: "",
    };
  },

  methods: {
    // before modal is opened, it recieve some params from site_image
    // image, images and alt
    beforeOpen(event) {
      let imageIndex = 0;
      let imageAlt = "";
      this.images.forEach((value, index) => {
        if (value.picture === event.params.image) {
          imageIndex = index;
          imageAlt = event.params.alt;
        }
      });
      // carousel accept props as navigate to find the right picture
      this.navigateTo = this.navigateOnImage(event.params);
      this.alt = imageAlt;
    },
    webpizeImage(image) {
      const segments = image.replace("og", "og_webp").split(".");
      segments.pop();
      return `${segments.join(".")}.webp`;
    },
    navigateOnImage(params) {
      for (const [index, image] of this.images.entries()) {
        if (params.alt === image.alt) {
          return index;
        }
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  width: 100%;
  max-height: 80vh;
  border-radius: 10px;
  background-color: transparent !important;
}
@media only screen and (max-width: 800px) {
  .image-container {
    width: 85vw;
  }
}

img {
  max-width: 100%;
  border-radius: 10px;
  min-height: 400px;
}
</style>
