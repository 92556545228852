<template>
  <div class="card card__container card__container--medium">
    <div
      class="card__header card__header--big cover"
      :style="`background-image: url('` + student.cover + `');`"
    />
    <div class="card__content">
      <break />
      <p
        v-line-clamp:42="2"
        class="text bold card__two-line-text"
      >
        {{ student.name }}, {{ student.age }} ans, {{ student.studies }}, {{ student.city }}
      </p>
      <break :size="10" />
      <div
        v-sanitaize
        class="stars__star--yellow sub-title"
        v-html="student.stars"
      />
      <break />
      <p class="text text__paragraph text__paragraph--grey">
        {{ student.description }}
      </p>
      <p class="text text__paragraph text__paragraph--grey date">
        Commentaire de {{ student.student_name }} le {{ student.date }}
        <break
          :size="35"
          direction="bottom"
        />
      </p>
    </div>
    <div
      v-if="student.path != undefined"
      class="card__hover card__hover--brand"
    >
      <break :size="5" />
      <p class="text blod text--white">
        Découvrir
      </p>
    </div>
    <a
      v-if="student.path != undefined"
      :href="student.path"
      class="card__link--full"
      @click="$root.trackAhoy('Click', parseInt(student.id), 'Counselor')"
    />
  </div>
</template>

<script>
export default {
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
};
</script>


<style scoped>
  .card {
    margin: 20px 0;
    min-width: 300px;
    height: 550px;
  }

  @media only screen and (max-width : 800px) {
    .card {
      margin: 10px 0;
      min-width: 250px;
      width: 250px;
      height: 600px;
    }
  }

  @media only screen and (max-width : 350px) {
    .card {
      min-width: 240px;
      width: 240px;
      height: 600px;
    }
  }

  .text__paragraph {
    font-size: 14px;
  }

  .date {
    position: absolute;
    margin-top: 5px;
    bottom: 40px;
    left: 10px;
    right: 10px;
    font-weight: 300;
  }
  .card__two-line-text {
    height: 44px;
  }

</style>
