<template>
  <div :class="wrapperClass">
    <a
      :href="`#${goTo}`"
      :class="classes"
      :style="`width: ${width}%`"
    >{{ text }}</a>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
    goTo: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: 60,
    },
    wrapperClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
  },
};
</script>
<style scoped>
  a {
    z-index: 99999999
  }
</style>
