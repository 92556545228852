<template>
  <carousel
    :per-page="this.pageNumber"
    :autoplay="true"
    :loop="true"
    :scroll-per-page="false"
    :autoplay-timeout="2000"
    :per-page-custom="this.pageCustom"
    :space-padding="-5"
    :space-padding-max-offset-factor="-50"
    :pagination-enabled="false"
    :mouseDrag="false"
    :navigationEnabled="true"
  >
    <slide v-for="element in sliderElements" :key="element.id">
      <div :class="getSizeClass()">
        <component :is="getComponent" v-bind:[target]="element"></component>
      </div>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  props: {
    sliderElements: Array,
    target: String,
    path: String,
    classType: String,
    pageNumber: Number,
    pageCustom: Array,
  },
  components: {
    Carousel,
    Slide,
  },
  computed: {
    getComponent() {
      // Cette erreur n'a pas pu etre enlevee. A investiguer
      return () => import(`../${this.path}`);
    },
  },
  methods: {
    getSizeClass() {
      if (this.$props.classType === "logo") {
        return `around around--logos`;
      } else if (this.$props.classType === "around") {
        return `around`;
      } else if (this.$props.classType === "flex") {
        return `flex flex--vcenter`;
      }
    },
  },
};
</script>

<style scoped>
.around {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  width: 350px;
  margin: 30px 0;
}

.around--logos {
  width: 160px;
  height: 160px;
}
</style>
